<template>
  <div>
    <AppHeader title="门禁授权"/>
    <Panel class="tabs search-panel" :title="false">
      <div slot="content">
        <div class="line">
          <input class="line-input" id="eqName" @click="showRooms" v-model="room" @keydown="preventDefault" placeholder="门禁"/>
          <input class="line-input mt10" id="beginAt" @click="showPicker('beginAt')" :value="dateFormat(beginAt)" @keydown="preventDefault" placeholder="开始时间："/>
          <input class="line-input mt10" id="endAt" @click="showPicker('endAt')" :value="dateFormat(endAt)" @keydown="preventDefault" placeholder="结束时间"/>
          <div class="flex-dir-row mt10">
            <div class="flex-dir-row flex-item-auto">
              <input class="line-input" id="userName" v-model="userName" placeholder="实验人"/>
            </div>
            <div class="btn btn-small green ml5" @click="reset">重设</div>
            <div class="btn btn-small green ml5" @click="initAuths">查询</div>
          </div>
        </div>
      </div>
    </Panel>
    <Panel class="tabs mt15" :title="false">
      <div slot="content" v-infinite-scroll="loadAuths"
           infinite-scroll-disabled="loading"
           infinite-scroll-distance="10">
        <div class="line" v-for="(item,idx) of auths" style="position: relative;overflow: hidden">
          <div class="flex-dir-row">
            <div class="line-label">姓名：</div>
            {{ item.user.realName }}
          </div>

          <div class="flex-dir-row flex-item-auto">
            <div class="line-label">门禁：</div>
            {{ item.room }}
          </div>

          <div class="flex-dir-row">
              <div class="line-label">时间：</div>
              {{ dateFormat(item.beginAt) }} ~ {{ dateFormat(item.endAt) }}
          </div>

          <div class="flex-dir-row">
            <div class="flex-dir-row flex-item-auto">
              <div class="line-label">状态：</div>
              {{ item.statusText }}
            </div>
            <div class="flex-dir-row">
              <div class="btn btn-small green" @click="retryAdd(item.id)">重试添加</div>
            </div>
          </div>
        </div>
        <div class="line text-center" v-if="auths.length===0">暂无记录</div>
      </div>
    </Panel>

    <mt-datetime-picker
        v-model="dateVal"
        type="datetime"
        @confirm="handleConfirm"
        ref="picker"
    >
    </mt-datetime-picker>

    <mt-popup
        v-model="roomsVisible"
        position="bottom"
    >
      <mt-picker :slots="roomSlots" @change="onRoomChange" value-key="text"
                 v-if="roomsVisible"></mt-picker>
    </mt-popup>

  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import Panel from "@/components/Panel";
import {InfiniteScroll, DatetimePicker, Picker, MessageBox} from 'mint-ui';
import {mapState} from 'vuex'

export default {
  components: {
    AppHeader,
    Panel,
    InfiniteScroll,
    DatetimePicker,
    Picker
  },
  data() {
    return {
      page: 1,
      auths: [],
      loading: false,
      noMore: false,
      startDate: this.$moment().add(-5, "years").toDate(),
      beginAt: null,
      endAt: null,
      room: null,
      userName: null,

      dateVal: new Date(),
      pickerField: '',

      roomsVisible: false,
    }
  },
  computed: mapState({
    // 为了能够使用 `this` 获取局部状态，必须使用常规函数
    roomSlots(state) {
      return [{values: state.guards}]
    }
  }),
  methods: {
    onRoomChange(picker, values) {
      this.room = values[0].text
    },
    showRooms() {
      this.roomsVisible = true
    },
    showPicker(pickerField) {
      this.dateVal = new Date()
      this.pickerField = pickerField
      this.$refs.picker.open()
    },
    handleConfirm(value) {
      if (this.pickerField === 'beginAt') {
        this.beginAt = this.$moment(value).valueOf()
      } else {
        this.endAt = this.$moment(value).valueOf()
      }
    },
    reset() {
      this.page = 1
      this.loading = false
      this.beginAt = null
      this.endAt = null
      this.userName = ''
      this.room = ''
      this.equipmentId = ''
    },
    dateFormat(timestamp) {
      if (timestamp) {
        return this.$moment(timestamp).format('YY-MM-DD HH:mm')
      }
      return ''
    },
    initAuths() {
      this.auths = []
      this.page = 1
      this.noMore = false
      this.loadAuths()
    },
    loadAuths() {
      if (this.noMore) {
        return
      }
      const that = this
      this.loading = true
      let data = new FormData()
      data.append('page', `${this.page}`)
      data.append('limit', '20')
      this.equipmentId && data.append('equipmentId', this.equipmentId)
      this.beginAt && data.append('beginAt', `${this.beginAt}`)
      this.endAt && data.append('endAt', `${this.endAt}`)
      this.userName && data.append('userName', `${this.userName}`)
      //?orderByBeginAtDesc=true&page=1&limit=10&equipmentId=&userId=&beginAt=&endAt=&deptId=&advisorId=&status=
      that.$http.post('/portal/guardAuth/query', data).then(res => {
        if (res.data && res.data.length > 0) {
          const auths = res.data.map(a => {
            if (a.status === 0) {
              a.statusText = '添加成功'
            } else if (a.status === -1) {
              a.statusText = '已删除'
            } else if (a.status === 1) {
              a.statusText = '删除失败'
            } else if (a.status === 4) {
              a.statusText = '添加失败'
            }
            return a;
          })
          that.auths = that.auths.concat(auths)
          that.loading = false
          that.page = that.page + 1
        } else {
          this.noMore = true
        }
      })
    },
    preventDefault(e) {
      e.preventDefault()
    },
    retryAdd(id) {
      const that = this
      MessageBox.confirm('确定要重试?').then(action => {
        that.$http.post(`/portal/guardAuth/retryAdd?id=${id}`).then(res => {
          that.initAuths()
        })
      })
    }
  },
  mounted() {
    this.initAuths()
  }
}
</script>